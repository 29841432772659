import React from 'react';
import { ButtonBase } from '@web-passion/uikit';

import loadComponents from '../Loadable';
import { GreenColour, OrangeColour } from '../../utils/variables';

const Link = loadComponents('link');

export default function Button(props) {
  const {
    to,
    children,
    primary,
    secondary,
    green,
    orange,
    ref,
    style,
    size,
    className,
    disabled,
    ...other
  } = props;

  const customColor = () => {
    if (green) {
      return GreenColour;
    }
    if (orange) {
      return OrangeColour;
    }
    return undefined;
  };

  const textColor = () => {
    if (green || orange) {
      return '#fff';
    }
    return undefined;
  };

  const innerProps = {
    className,
    primary,
    secondary,
    style: {
      ...style,
      display: `flex`,
      textTransform: `uppercase`,
      fontFamily: 'Lato',
    },
    color: customColor(),
    textColor: textColor(),
    size,
  };
  return (
    <Link
      to={to}
      className={`btnLink${disabled ? ` disabled` : ``}`}
      {...other}
    >
      <ButtonBase {...innerProps}>{children}</ButtonBase>
    </Link>
  );
}
